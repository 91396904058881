import React from 'react';
import _ from 'lodash';

import {getData} from '../utils';
import ImprensaPostFeedItem from './ImprensaPostFeedItem';

export default class ImprensaFeedItemFilter extends React.Component {
    render() {
        let section = _.get(this.props, 'imprensa_feed_section', null);
        let section_author = _.get(this.props, 'section_author', null);
        let section_category = _.get(this.props, 'section_category', null);
        let section_tag = _.get(this.props, 'section_tag', null);
        let imprensa = _.get(this.props, 'imprensa_page', null);
        return (
            section_author ? (
            	_.get(imprensa, 'frontmatter.author', null) && ((() => {
            	    let imprensa_author = getData(this.props.pageContext.site.data, _.get(imprensa, 'frontmatter.author', null));
            	    return (
                		(imprensa_author.id === _.get(section_author, 'id', null)) && (
                			<ImprensaPostFeedItem {...this.props} imprensa_feed_section={section} imprensa_page={imprensa} />
                		)
                	);
            	})())
            ) : (section_category ? (
            	_.map(_.get(imprensa, 'frontmatter.categories', null), (category, category_idx) => {
            	    let imprensa_category = getData(this.props.pageContext.site.data, category);
            	    return (
                		(imprensa_category.id === _.get(section_category, 'id', null)) && (
                			<ImprensaPostFeedItem key={category_idx} {...this.props} imprensa_feed_section={section} imprensa_page={imprensa} />
                		)
                	)
            	})
            ) : (section_tag ? (
            	_.map(_.get(imprensa, 'frontmatter.tags', null), (tag, tag_idx) => {
            	    let imprensa_tag = getData(this.props.pageContext.site.data, tag);
            	    return (
                		(imprensa_tag.id === _.get(section_tag, 'id', null)) && (
                			<ImprensaPostFeedItem key={tag_idx} {...this.props} imprensa_feed_section={section} imprensa_page={imprensa} />
                		)
                	)
            	})
            ) : 
            	<ImprensaPostFeedItem {...this.props} imprensa_feed_section={section} imprensa_page={imprensa} />
            ))
        );
    }
}

import React from 'react';
// import _ from 'lodash';

import Slider from "react-slick";


export default class CarouselHero extends React.Component {
  render() {
    const settings = {
      dots: false,
      fade: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            dots: true
          }
        },
      ]
    };
    return (
      <div className="carousel-v2">
      <Slider {...settings}>
        <div className="slide banner-1">
          <div className="slide-content inner">
            <img class="imagem-banner" src="/images/banners/banner-site-atualizado.png" alt="banner"></img>
          </div>
        </div>
        <div className="slide banner-2">
          <div className="slide-content inner">
            <img class="imagem-banner" src="/images/banners/bg-loccitane.jpg" alt="banner"></img>
          </div>
        </div>
      </Slider>
    </div>
    );
  }
}

import React from 'react';
import _ from 'lodash';

import {classNames, toStyleObj, withPrefix, getData, getPages} from '../utils';
import PortfolioFeedItemFilter from './PortfolioFeedItemFilter';
import SectionActions from './SectionActions';

export default class PortfolioFeedSection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        let align_x = _.get(section, 'align', null) || 'center';
        let padding_top = _.get(section, 'padding_top', null) || 'medium';
        let padding_bottom = _.get(section, 'padding_bottom', null) || 'medium';
        let bg_color = _.get(section, 'background_color', null) || 'none';
        let bg_img_opacity_pct = _.get(section, 'background_image_opacity', null) || 100;
        let bg_img_opacity = bg_img_opacity_pct * 0.01;
        let bg_img_size = _.get(section, 'background_image_size', null) || 'cover';
        let bg_img_position = _.get(section, 'background_image_position', null) || 'center center';
        let bg_img_repeat = _.get(section, 'background_image_repeat', null) || 'no-repeat';
        let section_author = false;
        let section_category = false;
        let section_tag = false;
        let portfolios_all = getPages(this.props.pageContext.pages, '/portfolio');
        let portfolios_sorted = _.orderBy(portfolios_all, 'frontmatter.date', 'desc');
        let show_recent = _.get(section, 'show_recent', null) || false;
        let recent_count = _.get(section, 'recent_count', null) || 0;
        let portfolio_count = 0;
        if (_.get(section, 'author', null)) {
             section_author = getData(this.props.pageContext.site.data, _.get(section, 'author', null));
        }
        if (_.get(section, 'category', null)) {
             section_category = getData(this.props.pageContext.site.data, _.get(section, 'category', null));
        }
        if (_.get(section, 'tag', null)) {
             section_tag = getData(this.props.pageContext.site.data, _.get(section, 'tag', null));
        }
        return (
            <section className={classNames('section', 'portfolio-feed', {'has-border': _.get(section, 'has_border', null), 'has-cover': _.get(section, 'background_image', null), 'bg-none': bg_color === 'none', 'bg-primary': bg_color === 'primary', 'bg-secondary': bg_color === 'secondary', 'pt-4': padding_top === 'small', 'pt-6': (padding_top === 'medium') || (padding_top === 'large'), 'pt-md-7': padding_top === 'large', 'pb-4': padding_bottom === 'small', 'pb-6': (padding_bottom === 'medium') || (padding_bottom === 'large'), 'pb-md-7': padding_bottom === 'large'})}>
            	{_.get(section, 'background_image', null) && (
            	<div className="cover-img" style={toStyleObj('background-image: url(\'' + withPrefix(_.get(section, 'background_image', null)) + '\'); opacity: ' + bg_img_opacity + '; background-size: ' + bg_img_size + '; background-repeat: ' + bg_img_repeat + '; background-position: ' + bg_img_position)}/>
            	)}
            	{(_.get(section, 'title', null) || _.get(section, 'subtitle', null)) && (
            	<div className={classNames('container', 'container--medium', 'mb-5', {'text-center': align_x === 'center', 'text-right': align_x === 'right'})}>
            		{_.get(section, 'subtitle', null) && (
            		<div className="section__subtitle">{_.get(section, 'subtitle', null)}</div>
            		)}
            		{_.get(section, 'title', null) && (
            		<h2 className="section__title mt-0">{_.get(section, 'title', null)}</h2>
            		)}
            	</div>
            	)}
            	<div className="container">
					<div className="filtro portfolio-filtro mb-4">
						<a href="/portfolio"> Todos </a>
						<a href="/portfolio/category/prepago" className="portfolio-pre-pago"> Cartão Pré-pago </a>
						<a href="/portfolio/category/virtual" className="portfolio-virtual"> Cartão Virtual </a>
						<a href="/portfolio/category/fisico" className="portfolio-fisico"> Cartão Físico </a>
						<a href="/portfolio/category/recarga" className="portfolio-recarga"> Cartões de Recarga </a>
						<a href="/portfolio/category/presente" className="portfolio-presente"> Cartões Presente </a>
						<a href="/portfolio/category/ingressos" className="portfolio-ingressos"> Ingressos </a>
						<a href="/portfolio/category/voucher" className="portfolio-voucher"> Voucher </a>
					</div>
            		<div className="grid">
            			{_.map(portfolios_sorted, (portfolio, portfolio_idx) => {
						
            			    let is_portfolio = false;
            			    if ((_.get(portfolio, 'frontmatter.template', null) === 'portfolio')) {
            			         is_portfolio = true;
            			    }
            			    return (<React.Fragment key={portfolio_idx + '.1'}>
                				{(is_portfolio && ((show_recent === false) || (portfolio_count < recent_count))) && ((() => {
                				     portfolio_count = portfolio_count + 1;
                				    return (
                    				<PortfolioFeedItemFilter key={portfolio_idx} {...this.props} portfolio_feed_section={section} portfolio_page={portfolio} section_author={section_author} section_category={section_category} section_tag={section_tag} />
                    				);
                				})())}
                			</React.Fragment>)
            			})}
            		</div>
            	</div>
            	{_.get(section, 'actions', null) && (
            	<div className="container container--medium mt-4">
            		<div className={classNames('section__actions', 'btn-group', {'justify-center': align_x === 'center', 'justify-end': align_x === 'right'})}>
            			<SectionActions {...this.props} actions={_.get(section, 'actions', null)} />
            		</div>
            	</div>
            	)}
            </section>
        );
    }
}
